import logo from "../assets/logo.png";
import { footer } from "../data";

const Footer = () => {
  return (
    <section className="bg-[#CEDCFF] mx-4 md:mx-10 mb-10 rounded-2xl px-5 pt-16 pb-8">
      <div>
        <img src={logo} alt="logo" className="h-12 md:h-14 lg:h-24 mb-8" />
        <p className="text-xl md:text-2xl font-light leading-8 mb-10 lg:w-[80%]">
          {footer.desc}
        </p>
      </div>
    </section>
  );
};

export default Footer;
